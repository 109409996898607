import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SelectCompetence from "./selectCompetence";
import SelectUser from "./selectUser";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom"; // Ensure useNavigate is imported
import { Divider } from "@mui/material";

function FormateurDashboard(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate hook here
  const { currentUserId } = location.state || { currentUserId: 0 };
  const { prenom } = location.state || { prenom: "firstname" };
  const { nom } = location.state || { nom: "lastname" };

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const [competences, setCompetences] = useState([]);
  const [selectedCompetence, setSelectedCompetence] = useState("");

  useEffect(() => {
    const fetchCompetences = async () => {
      try {
        const { data } = await axios.get(
          `http://localhost:8080/collectionListByUserId?id=${currentUserId}`
        );
        setCompetences(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchCompetences();
  }, [currentUserId]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await axios.get(
          `http://localhost:8080/allApprentis?id=${currentUserId}`
        );
        console.log(data);
        setUsers(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUsers();
  }, [currentUserId]);

  const handleCancel = () => {
    console.log("Action annulée !");
  };

  const handleValidate = async () => {
    try {
      const response = await fetch(
        `http://localhost:8080/addValidation?id_competence=${selectedCompetence}&id_apprenti=${selectedUser}&id_formateur=${currentUserId}`
      );

      if (response.ok) {
        console.error("Validation OK");
      } else {
        console.error("Failed to add post");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Function to handle logout and navigate to login
  const handleLogout = () => {
    // Perform any logout logic here, like clearing user data
    navigate("/login");
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Formateur Dashboard
          </Typography>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <Container sx={{ mt: 4 }}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            p: 3,
            border: "1px solid #ddd",
            borderRadius: 2,
            boxShadow: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Typography align="left" variant="h6">
              Bonjour Mr. {"\t"}
              {prenom}
              {"\t"}
              {nom}
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Typography align="left" variant="subtitle1">
              Ici, vous êtes invité à valider une compétence pour un apprenti.
              <br />
              Merci de choisir la personne concernée et la bonne compétence.
            </Typography>
          </Box>
          <Divider />
          <SelectUser
            users={users}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
          <Divider />
          <SelectCompetence
            competences={competences}
            selectedCompetence={selectedCompetence}
            setSelectedCompetence={setSelectedCompetence}
          />
          <Box sx={{ display: "flex", gap: 10 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleValidate}
            >
              Valider
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCancel}>
              Annuler
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default FormateurDashboard;
