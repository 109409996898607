import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { DensitySmall, Padding } from "@mui/icons-material";
import { useEffect, useState } from "react";
import axios from "axios";
import { Paper } from "@mui/material";

export default function DrawerUser(props) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <div>id_user : {props.currentUserId}</div>
      <div>
        selon son titre,\n il y a {props.competences.length} compétences
      </div>

      <List>
        <div>
          <ul style={{ listStyleType: "none", padding: 0, marginLeft: 10 }}>
            {props.competences.map((competence, index) => (
              <React.Fragment key={competence[0]}>
                <li
                  style={{
                    fontWeight: competence[2] ? "bold" : "normal",
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  {competence[3] === 1 && (
                    <CheckCircleIcon
                      style={{ color: "green", marginRight: "8px" }}
                    />
                  )}
                  {competence[1]}
                </li>
                {index < props.competences.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <DensitySmall sx={{ color: "white" }} />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
