import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import PostListForGivenUser from "./postListForGivenUser";
import ResponsiveAppBar from "./ResponsiveAppBar";
import axios from "axios";
import { useLocation } from "react-router-dom";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.ldnr.fr/" target="_blank">
        www.ldnr.fr
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
const defualtUser = 3;

export default function Dashboard(props) {
  //
  const location = useLocation();
  const { currentUserId } = location.state || { currentUserId: 0 };

  //
  const [open, setOpen] = useState(true);
  const [anchorElUser, setAnchorElUser] = useState(null);
  //const [currentUserId, setCurrentUserId] = useState(defualtUser);
  const [posts, setPosts] = useState([]);
  const [competences, setCompetences] = useState([]);
  const [content, setContent] = useState();

  /*............  Les posts de l'utilisateur pour remplir les passer à
  PostListForGivenUser
  ..............*/
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const { data } = await axios.get(
          `http://localhost:8080/getPostsUser?id_user=${currentUserId}`
        );
        setPosts(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchPosts();
  }, [currentUserId]);

  /*................  Les compétences de l'utilisateur selon son titre (competences)
   pour remplir le DrawerUser     ..............*/

  useEffect(() => {
    const fetchCompetences = async () => {
      try {
        const { data } = await axios.get(
          `http://localhost:8080/collectionListByUserId?id=${currentUserId}`
        );
        //console.log(data);
        setCompetences(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchCompetences();
  }, [currentUserId]);
  //..............................................................................

  /*....La list de tous Users pour remplir le compenent : SelectUser.....*/
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await axios.get(`http://localhost:8080/allUsers`);
        console.log(data);
        setUsers(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUsers();
  }, []);

  //...............handlePostDelete......................
  const handlePostDelete = (post) => {
    fetch(`http://localhost:8080/deletePost?id=${post.id_post}`, {
      // method: "DELETE",
      mode: "no-cors",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);
      });
    setPosts(posts.filter((p) => p.id_post !== post.id_post));
  };

  //....................................

  //...............handlePostUpdate......................
  const handlePostUpdate = (post) => {
    fetch(`http://localhost:8080/addPost?contenu=${post.contenu}`, {
      // method: "DELETE",
      mode: "no-cors",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);
      });
    //props.setPosts(posts.filter((post) => post.id_post !== id_post));
  };

  //....................................

  const toggleDrawer = () => {
    setOpen(!open);
  };

  //

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDelete = (event) => {
    console.log(event);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "none",
          }}
        >
          <ResponsiveAppBar
            currentUserId={currentUserId}
            competences={competences}
            settings={settings}
          />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <PostListForGivenUser
                    handlePostDelete={handlePostDelete}
                    setPosts={setPosts}
                    posts={posts}
                    users={users}
                    currentUserId={currentUserId}
                    competences={competences}
                    setContent={setContent}
                  />

                  {/* <DataTable rows={props.rows} columns={props.columns}></DataTable> */}
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
