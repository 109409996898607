import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  IconButton,
  TablePagination,
  Box,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import ModalEditPost from "./Modal/ModalEditPost";
import ModalAddPost from "./Modal/ModalAddPost";

export default function PostListForGivenUser(props) {
  const {
    posts,
    competences,
    currentUserId,
    handlePostDelete,
    handlePostAdd,
    setPosts,
  } = props;

  const [isModalEditPostOpen, setisModalEditPostOpen] = useState(false);
  const [isModalAddPostOpen, setisModalAddPostOpen] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleEditPost = (post) => {
    setCurrentPost(post);
    setisModalEditPostOpen(true);
  };

  const handleSave = async (updatedPost) => {
    try {
      const response = await fetch(
        `http://localhost:8080/updatePost?id_post=${updatedPost.id_post}&contenu=${updatedPost.contenu}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const updatedPost = await response.json();
        console.log("Post updated:", updatedPost);
      } else {
        console.error("Failed to update post");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setPosts(
      posts.map((post) =>
        post.id_post === updatedPost.id_post ? updatedPost : post
      )
    );
    setisModalEditPostOpen(false);
    setCurrentPost(null);
  };

  const handleAddPost = async (newPost) => {
    try {
      const response = await fetch(
        `http://localhost:8080/addPost?contenu=${newPost.content}&id_competence=${newPost.id_competence}&id_apprenti=${currentUserId}&id_posteur=${currentUserId}`,
        {
          //const response = await fetch(`http://localhost:8080/addPost`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newPost),
        }
      );

      if (response.ok) {
        const addedPost = await response.json();
        console.log("Post added:", addedPost);
        setPosts([...posts, addedPost]);
      } else {
        console.error("Failed to add post");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setisModalAddPostOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedPosts = posts.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div style={{ textAlign: "right" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setisModalAddPostOpen(true)}
      >
        Add Post
      </Button>
      <List>
        {paginatedPosts.map((post, index) => (
          <div key={post.id}>
            <Paper
              elevation={10}
              style={{
                marginBottom: "8px",
                padding: "8px",
                position: "relative",
              }}
            >
              <ListItem>
                <Box
                  component="span"
                  sx={{
                    display: "block",
                  }}
                >
                  <p>
                    <ListItemText>{post.contenu}</ListItemText>
                    <Divider style={{ backgroundColor: "white" }} />
                  </p>

                  <ListItemText>
                    <p>Nom de la compétence : {post.competence.nom}</p>
                  </ListItemText>
                  <Divider style={{ backgroundColor: "white" }} />

                  <ListItemText>
                    <p>
                      Le posteur : {post.posteur.prenom} {post.posteur.nom}
                    </p>
                  </ListItemText>

                  <Divider style={{ backgroundColor: "white" }} />

                  <ListItemText>
                    <p>
                      L'apprenti: {post.apprenti.prenom} {post.apprenti.nom}
                    </p>
                  </ListItemText>

                  <Box>
                    <div style={{ marginTop: "10px" }}>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => handleEditPost(post)}
                      >
                        <EditIcon />
                      </IconButton>

                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          handlePostDelete(post);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Box>
                </Box>
              </ListItem>
            </Paper>
          </div>
        ))}
      </List>

      {isModalEditPostOpen && (
        <ModalEditPost
          post={currentPost}
          onSave={handleSave}
          onClose={() => setisModalEditPostOpen(false)}
        />
      )}

      {isModalAddPostOpen && (
        <ModalAddPost
          competences={competences}
          currentUserId={currentUserId}
          setisModalAddPostOpen={setisModalAddPostOpen}
          onSave={handlePostAdd}
          onClose={() => setisModalAddPostOpen(false)}
        />
      )}

      <TablePagination
        component="div"
        count={posts.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </div>
  );
}
