import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

export default function SelectCompetence(props) {
  const competences = props.competences;
  const selectedCompetence = props.selectedCompetence;
  const setSelectedCompetence = props.setSelectedCompetence;

  return (
    <FormControl fullWidth>
      <InputLabel id="select-competence-label">Competence</InputLabel>
      <Select
        labelId="select-competence-label"
        value={selectedCompetence}
        onChange={(e) => setSelectedCompetence(e.target.value)}
      >
        {competences.map((competence) => (
          <MenuItem key={competence[0]} value={competence[0]}>
            {competence[1]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
