import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

function ModalEditPost({ post, onSave, onClose }) {
  const [contenu, setContenu] = useState("");

  useEffect(() => {
    if (post) {
      setContenu(post.contenu);
    }
  }, [post]);

  const handleSave = () => {
    onSave({ ...post, contenu });
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Edit Post</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please edit the content of your post.
        </DialogContentText>
        <textarea
          cols="35"
          rows="5"
          autoFocus
          margin="dense"
          id="contenu"
          label="Content"
          type="text"
          fullWidth
          value={contenu}
          onChange={(e) => setContenu(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default ModalEditPost;
