import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

export default function SelectUser(props) {
  const users = props.users;
  const selectedUser = props.selectedUser;
  const setSelectedUser = props.setSelectedUser;
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">User</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="User"
        onChange={(event) => setSelectedUser(event.target.value)}
      >
        {users.map((user) => (
          <MenuItem key={user.id_user} value={user.id_user} name={user.prenom}>
            {user.nom}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
