import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";

const ModalAddPost = ({
  competences,
  currentUserId,
  onSave,
  onClose,
  setisModalAddPostOpen,
}) => {
  const [content, setContent] = useState("");
  const [selectedCompetence, setSelectedCompetence] = useState("");

  const handleSave = () => {
    const newPost = {
      contenu: content,
      //id_competence: competences.find((c) => c[1] === selectedCompetence),
      id_competence: selectedCompetence,
      id_posteur: currentUserId,
      id_apprenti: currentUserId,
    };
    onSave(newPost);
  };

  const onAddPost = async () => {
    //console.log(currentUserId, selectedCompetence, content);
    try {
      const response = await fetch(
        `http://localhost:8080/addPost?contenu=${content}&id_competence=${selectedCompetence}&id_apprenti=${currentUserId}&id_posteur=${currentUserId}`
      );

      if (response.ok) {
        const addedPost = await response.json();
      } else {
        console.error("Failed to add post");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setisModalAddPostOpen(false);
  };

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2">
          Add New Post ...
        </Typography>
        <TextField
          label="Content"
          multiline
          rows={4}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          fullWidth
          sx={{ mt: 2 }}
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="select-competence-label">Competence</InputLabel>
          <Select
            labelId="select-competence-label"
            value={selectedCompetence}
            onChange={(e) => setSelectedCompetence(e.target.value)}
          >
            {competences.map((competence) => (
              <MenuItem key={competence[0]} value={competence[0]}>
                {competence[1]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={onAddPost}
          sx={{ mt: 2 }}
        >
          Save
        </Button>
        <Button onClick={onClose} sx={{ mt: 2 }}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalAddPost;
